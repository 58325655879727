<template>
  <div>
    <div >
      <h1>AQI Comparison</h1>
      <!-- <div style="width:400px">
      <line-chart :chart-data="datacollection"></line-chart>
      </div> -->
      <div style="width:400px">
      <!-- <bar-chart :chart-data="barchartdatacollection"></bar-chart> -->
      <!-- <pie-chart :chart-data="piechartdatacollection"></pie-chart> -->
       <doughnut-chart :chart-data="doughnutdatacollection"></doughnut-chart> 
      </div>
    </div>
  </div>
</template>

<script>
// import LineChart from "./LineChart.js";
// import BarChart from "./BarChart.js";
// import PieChart from "./PieChart.js";
 import DoughnutChart from "./DoughnutChart.js";

export default {
  name: "AQIChartComponent",
  components: {
    //  LineChart,
    // BarChart,
    // PieChart,
    DoughnutChart
  },
  data() {
    return {
      datacollection: {
        labels: [
          "week 1",
          "week 2",
          "week 3",
          "week 4",
          "week 5",
          "week 6",
          "week 7",
          "week 8",
          "week 9",
          "week 10",
        ],
        datasets: [
          {
            data: [86, 1114, 106, 106, 107, 111, 133, 221, 783, 2478],
            label: "Africa",
            borderColor: "#3e95cd",
            fill: false,
          },
          // {
          //   data: [282, 350, 411, 502, 635, 809, 947, 1402, 3700, 5267],
          //   label: "Asia",
          //   borderColor: "#8e5ea2",
          //   fill: false,
          // },
          // {
          //   data: [168, 170, 178, 190, 203, 276, 408, 547, 675, 734],
          //   label: "Europe",
          //   borderColor: "#3cba9f",
          //   fill: false,
          // },
          // {
          //   data: [40, 20, 10, 16, 24, 38, 74, 167, 508, 784],
          //   label: "Latin America",
          //   borderColor: "#e8c3b9",
          //   fill: false,
          // },
          // {
          //   data: [6, 3, 2, 2, 7, 26, 82, 172, 312, 433],
          //   label: "North America",
          //   borderColor: "#c45850",
          //   fill: false,
          // },
        ],
      },
      barchartdatacollection: {
        labels: [
          "Comfort",
          "Discomfort",
        ],
        datasets: [
          {
            data: [60,40,0,90,100],
            label: "Comfort",
            backgroundColor:  ['#41B883', '#E46651'],
          },                   
        ],
      },
      piechartdatacollection: {
        labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            data: [40, 20, 80, 10]
          }
        ]
      },
      doughnutdatacollection: {
       // labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
          {
            backgroundColor: ['#4880FF', '#4880FF4D'],
            data: [40, 20]
          }
        ]
      }
    };
  },
};
</script>