<template>
  <div  >
    <AQIChartComponent />
     <!--<PieChart /> -->

  </div>
</template>

<script>
 import AQIChartComponent from "@/components/charts/AQIChartComponent";
// import PieChart from '@/components/charts/PieChart'

export default {
  name: "chart",
  components: {
     AQIChartComponent,
    //  PieChart
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
